import "./sass/main.scss";
import "bootstrap";
import PocketCowDapp from "./PocketCowDapp";
import heroVideo from "./res/videos/pacow-gameplay-9-22.mp4";

// Gameplay Video ==============================================================
window.addEventListener('load', () => {
	let elem = document.getElementById("pacow-gameplay-src");
	elem.setAttribute("src", heroVideo);
	elem.parentElement.load();
});

document.getElementById("infoaccordion").innerHTML = 
	require("./accordion_item.mustache")(require("./res/copy/cow_accordion.json"));

document.getElementById("roadmap-inner-items").innerHTML = 
	require("./roadmap_item.mustache")(require("./res/copy/roadmap_items.json"));

fetch("/leaderboard/json.php").then((resp) => {
	return resp.json();
}).then((arrScores) => {

	for(let i = 0; i < arrScores.length; ++i) {
		arrScores[i].place = i + 1;
	}

	document.getElementById("template-leaderboard").innerHTML = 
		require("./leaderboard.mustache")({items: arrScores});

});

let theDapp = new PocketCowDapp();

theDapp.on("update", (data) => {

	var saleProgressBars = [
		document.getElementById("saleProgressBar1"),
		document.getElementById("saleProgressBar2"),
		document.getElementById("saleProgressBar3"),
		document.getElementById("saleProgressBar4")
	]

	for(var i = 0; i < 4; ++i) {

		var localMaxCount = data.max / 4;
		var localMintCount = Math.max(data.minted - i * localMaxCount, 0);
		saleProgressBars[i].style.height = `${localMintCount / localMaxCount * 100}%`;

	}
	
	document.getElementById("mintProgressText").innerHTML = `Cows Minted: ${data.minted}/${data.max}`;
	document.getElementById("burntCount").innerHTML = `${data.burnt}/${data.max}`;
	document.getElementById("burntProgressBar").style.width = `${data.burnt / data.max * 100}%`;
	
	if(data.connected) {
		document.getElementById("btn-account-connect-container").classList.add("connect-complete");
		document.getElementById("walk-2").classList.add("complete");
	}

});

theDapp.on("cows", (arrCows) => {

	document.getElementById("cowasel-wrapper").innerHTML = 
		require("./pasture_carousel.mustache")({ items: arrCows });

});

theDapp.on("balances", (balanceInfo) => {

	if(balanceInfo.eth > 0.07)
		document.getElementById("walk-3").classList.add("complete");

	if(balanceInfo.cows > 0)
		document.getElementById("walk-4").classList.add("complete");

});

theDapp.on("pastureBalance", (amount) => {

	document.getElementById("pasture-balance-display").innerHTML = 
		`Your Balance: ${amount} Eth`;

});

document.getElementById("btn-account-connect").onclick = () => {
	theDapp.connect();
};

if(window.ethereum) {
	document.getElementById("walk-1").classList.add("complete");
}

// Minting Controls ============================================================
var g_mintCount = 1;
document.getElementById("mintButton").onclick = () => {
	theDapp.mintCows(g_mintCount);
};

onMintCountChanged();
function onMintCountChanged() {
	const doBonus = g_mintCount >= 10; 
	document.getElementById("mintButton").innerHTML = `<div>Mint ${g_mintCount} cow${ g_mintCount != 1 ? "s" : "" }! Do it now!</div> ${doBonus == true ? "<div>(+1 Bonus Mint)</div>" : ""}`;
	document.getElementById("mintDownButton").disabled = g_mintCount <= 1;
	document.getElementById("mintUpButton").disabled = g_mintCount >= 10;
}

document.getElementById("mintDownButton").onclick = () => {
	g_mintCount = Math.max(g_mintCount - 1, 0);
	onMintCountChanged();
};

document.getElementById("mintUpButton").onclick = () => {
	g_mintCount = Math.min(g_mintCount + 1, 10);
	onMintCountChanged();
};

// Pasture Controls ============================================================
document.getElementById("btnPastureBurn").onclick = () => {
	let itemContainer = document.getElementById("cowasel-inner");
	let cowElement = itemContainer.querySelector(".active");
	let nIDCow = parseInt(cowElement.id);
	theDapp.burnCow(nIDCow);
};

document.getElementById("pasture-withdraw-button").onclick = () => {
	theDapp.pastureWidthdraw();
};