import { ethers } from "ethers";
import { EventEmitter } from "events";
import placeholderCow from "./res/images/blank.jpg";

export default class PocketCowDapp {

	constructor() {

		this.m_emitter = new EventEmitter();
		this.m_adrPocketCows = "0xaa18355bcfc8ae878ee4f65fea2158bd159eb521";
		this.m_adrPasture = "0x36D3b60a009A9e98Dd90Ee6658450d7deC24e468";
		this.m_contractPocketCows = null;
		this.m_contractPasture = null;
		this.m_unMintCount = 0;

		this.setProvider(new ethers.providers.AlchemyProvider("homestead", 
			"I9fDQYHfDVyglML4f_cpgsqBYEy_sDvG"));
	}

	async setProvider(provider) {

		this.m_provider = provider;

		let accounts = await provider.listAccounts();
		let signerOrProvider = accounts.length > 0 ? provider.getSigner() : provider;

		let jsonPC = require("./res/abi/PocketCow.json");
		this.m_contractPocketCows = new ethers.Contract(this.m_adrPocketCows,
			jsonPC.abi, signerOrProvider);

		let jsonPasture = require("./res/abi/PCPasture.json");
		this.m_contractPasture = new ethers.Contract(this.m_adrPasture,
			jsonPasture.abi, signerOrProvider);

		var burntCount = parseInt(await this.m_contractPocketCows.BurntTokenTotal());
		var mintedCount = parseInt(await this.m_contractPocketCows.totalSupply()) + burntCount;
		var maxCount = await this.m_contractPocketCows.MAX_TOKEN_COUNT();

		this.m_emitter.emit("update", {
			minted: mintedCount,
			burnt: burntCount,
			max: maxCount,
			connected: this.m_contractPocketCows.signer != null
		});

		this.getCows().then((arrCows) => {
			this.m_emitter.emit("cows", arrCows);
		});

		this.m_provider.on("block", this.onBlock.bind(this));

	}

	async onBlock() {

		if((await this.m_provider.listAccounts()).length < 1)
			return;

		let signer = this.m_provider.getSigner();

		this.m_provider.getBalance(signer.getAddress()).then((balance) => {

			return parseFloat(ethers.utils.formatEther(balance));

		}).then((ethBalance) => {

			return Promise.all([ethBalance, this.m_contractPocketCows.balanceOf(signer.getAddress())]);

		}).then(([ethBalance, cowBalance]) => {
			
				this.m_emitter.emit("balances", {
					eth: ethBalance,
					cows: cowBalance.toNumber()
				});

		});

		this.m_contractPasture.getBalance().then((balance) => {
			this.m_emitter.emit("pastureBalance", parseFloat(ethers.utils.formatEther(balance)));
		}).catch((res) => {
			this.m_emitter.emit("pastureBalance", 0);
		}); 

	}

	getCows() {

		return new Promise(async (resolve, reject) => {

			if(this.m_contractPocketCows.signer == null) {

				resolve([{
					active: true,
					id: "Disconnected",
					imgurl: placeholderCow,
					index: 0
				}]);

				return;

			}

			let arrCows = [];
			let address = await this.m_contractPocketCows.signer.getAddress();
			let indexCounter = 0;

			let nAccCowCount = parseInt(await this.m_contractPocketCows.balanceOf(address));
			for(let nAccCowIdx = 0; nAccCowIdx < nAccCowCount; ++nAccCowIdx) {

				this.m_contractPocketCows.tokenOfOwnerByIndex(address, nAccCowIdx).then((strTokenID) => {
		
					let nTokenID = parseInt(strTokenID);
					return Promise.all([this.m_contractPocketCows.tokenURI(nTokenID), nTokenID]);
		
				}).then(([strURLMeta, nTokenID]) => {
		
					return Promise.all([fetch(strURLMeta).then((resp) => {return resp.json()}), nTokenID]);
		
				}).then(([rxMeta, nTokenID]) => {
		
					arrCows.push({
						id: nTokenID,
						imgurl: rxMeta.image,
						index: () => {return indexCounter++}
					});

					if(arrCows.length >= nAccCowCount) {
						let arrSorted = arrCows.sort((a, b) => {return a.id - b.id;});
						arrSorted[0].active = true;
						resolve(arrSorted);
					}

				});
			}

		});
	}

	connect() {

		return new Promise(async (resolve, reject) => {
	
			const WalletConnectProvider = (await import("@walletconnect/web3-provider")).default;
			const CoinbaseWalletSDK = (await import("@coinbase/wallet-sdk")).default;
			
			const providerOptions = {
				walletconnect: {
					package: WalletConnectProvider,
					options: {
						rpc: {
							1: "https://eth-mainnet.alchemyapi.io/v2/GqU8Tq3I-k-nLdvEcyhkyVUy8IR4Nqwq"
						}
					}
				},
				coinbasewallet: {
					package: CoinbaseWalletSDK,
					options: {
						appName: "PocketCows",
						rpc: "https://eth-mainnet.alchemyapi.io/v2/GqU8Tq3I-k-nLdvEcyhkyVUy8IR4Nqwq",
					}
				}
			};
			
			const Web3Modal = (await import("web3modal")).default;
			const web3Modal = new Web3Modal({
				network: "mainnet", // optional
				cacheProvider: false, // optional
				providerOptions // required
			});
	
			web3Modal.connect().then((instance) => {
	
				this.setProvider(new ethers.providers.Web3Provider(instance));
				resolve(this.m_provider);
				this.m_emitter.emit("tutorial", 2);
	
			}).catch(reject);

		});
	}
		

	on(eventName, callback) {
		this.m_emitter.on(eventName, callback);
	}

	async mintCows(nCount) {

		if(this.m_contractPocketCows.signer == null) {
			alert("You must connect your wallet first.")
			return;
		}
	
		let valueToSend = ethers.BigNumber.from("60000000000000000");
		valueToSend = valueToSend.mul(nCount);
	
		await this.m_contractPocketCows.mintTokens(nCount, {gasLimit: 200000 * nCount, value: valueToSend});

	}

	burnCow(nIdCow) {

		if(this.m_contractPocketCows.signer == null) {
			alert("You must connect your wallet first.")
			return;
		}

		if(!window.confirm(`Are you sure you want to send your cow to pasture? This cannot be undone.`))
			return;

		this.m_contractPocketCows.burnToken(nIdCow);

	}

	pastureWidthdraw() {

		if(this.m_contractPasture.signer == null) {
			alert("You must connect your wallet first.")
			return;
		}

		this.m_contractPasture.withdrawBalance({gasLimit: 94000});
	}

}